/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const updatesRoutes = {
  path: '/updates',
  component: Layout,
  redirect: '/updates',
  name: 'Project Task',
  meta: {
    title: 'Project Task',
    icon: 'Handbag',
    // permissions: ['view menu updates'],
  },
  children: [
    {
      path: 'project-list',
      component: () => import('../views/updates/project/List'),
      name: 'project List',
      meta: {
        title: 'project List',
        icon: 'Memo',
        permissions: ['update project'],
      },
    },
    {
      path: 'project-edit/:id(\\d+)',
      component: () => import('../views/updates/project/Edit'),
      name: 'projectEdit',
      meta: {
        title: 'projectEdit',
        icon: 'Memo',
        permissions: ['update project'],
      },
      hidden: true,
    },
    {
      path: 'assigned-list',
      component: () => import('../views/assaign-project/List'),
      name: 'assignedProjectList',
      meta: {
        title: 'Assign Project User',
        icon: 'Memo',
        permissions: ['update project'],
      },
    },
    // projects routes
    {
      path: 'wbc-updates',
      component: () => import('../views/updates/wbc-updates/List'),
      name: 'wbcUpdateList',
      meta: {
        title: 'WBC Update List',
        icon: 'Memo',
        // permissions: ['project wbc update list'],
      },
    },
    {
      path: 'wbc-update/create',
      component: () => import('../views/updates/wbc-updates/Create'),
      name: 'wbcUpdateCreate',
      meta: {
        title: 'Create WBC Update',
        icon: 'Memo',
        // permissions: ['add project wbc update'],
      },
      // hidden: true,
    },

    {
      path: 'wbc-update/view/:id(\\d+)',
      component: () => import('../views/updates/wbc-updates/View'),
      name: 'wbcProjectView',
      meta: {
        title: 'View WBC Update',
        icon: 'Memo',
        // permissions: ['view project wbc update'],
      },
      hidden: true,
    },
    {
      path: 'wbc-update/edit/:id(\\d+)',
      component: () => import('../views/updates/wbc-updates/Edit'),
      name: 'projectEditPage',
      meta: {
        title: 'View WBC Edit Page',
        icon: 'Memo',
        // permissions: ['view project wbc update'],
      },
      hidden: true,
    },
    {
      path: 'wbc-update/kanban-board',
      component: () => import('../views/updates/wbc-updates/board'),
      name: 'board',
      meta: {
        title: 'Kanban Board',
        icon: 'Memo',
        // permissions: ['view project wbc update'],
      },
      hidden: false,
    },
    {
      path: 'wbc-update/report',
      component: () => import('../views/updates/wbc-updates/report'),
      name: 'boardReport',
      meta: {
        title: 'Report',
        icon: 'Memo',
        // permissions: ['view project wbc update'],
      },
      hidden: false,
    },
  ],
};

export default updatesRoutes;
